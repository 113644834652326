import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalAddOutcomesEvent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 100%;
    height: 95%;
    margin-top: auto;
    box-sizing: border-box;
    border-radius: 14px;
    &--reaction {
      padding: 40px;
      display: flex;
    }
    .header {
      padding: 20px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E0E6ED;
      &__title {
        font-family: Nunito Sans Black;
        font-size: 30px;
        color: #3E3E5B;
      }
      &__subtitle {
        color: #3E3E5B;
        font-family: "Nunito Sans";
        font-size: 18px;
      }
      &__close {
        width: 28px;
        height: 28px;
        background-color: #36369B;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #fff;
      }
    }
    .rows-container {
      height: calc(100% - 182px);
      overflow: auto;
      background-color: #F6F6FF;
      position: relative;
    }
    .rows {
      display: flex;
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
    .principal-input {
      padding: 20px 20px 0 40px;
      width: 30%;
      overflow: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      &__form {
        height: 100%;
      }
      &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      &__decorator {
        position: absolute;
        margin-left: -122px;
        bottom: 0;
      }
      .patient-box {  
        display: flex;
        align-items: center;
        margin: 30px 0;
        z-index: 100;
        &__img {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          object-fit: cover;
          font-size: 8px;
          vertical-align: middle;
        }
        &__info {
          display: flex;
          flex-direction: column;
          margin-left: 9px;
          justify-content: space-evenly;
          height: 100%;
        }
        &__name {
          font-family: Nunito Sans Bold;
          font-size: 21.16px;
          line-height: 1;
        }
        &__program {
          background-color: #EEF1F4;
          color: #526479;
          border-radius: 4px;
          width: fit-content;
        }
      }
    }
    .custom-form-inputs {
      padding: 20px 20px 20px 15px;
      background-color: #FFFFFF;
      width: 70%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-left: 5px solid #6C6BCC;
      overflow-y: auto;
      box-sizing: border-box;
      margin: 20px 40px 0 0;
      z-index: 100;
      display: flex;
      flex-direction: column;
    }
    .input-box-time {
      padding: 12px 0px 30px 0px;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      &__icon {
        color: #3E3E5B; 
        margin-right: 10px;
      }
      .input-content {
        align-items: center;
        display: flex;      
        margin-right: 20px;
        &__label-bold {
          color: #3E3E5B; 
          white-space: nowrap;
          margin-right: 10px;
          font-size: 15px;
        }
      }
      &__separator {
        border-left: 1px solid #E6E6E6;
        height: 16px;
        margin-left: 9px;
        margin-right: 29px;
      }
      .button-add-favourite {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        border: 1px solid #AAAADB;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        &__icon {
          font-size: 13px;
          color: #36369B;
        }
      }
    }
    .input-box {
      padding-bottom: 20px;
      color: #3E3E5B;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 100;
      &--last {
        padding-bottom: 0px;
        height: 100%;
        z-index: 50;
      }
      &__label {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-bottom: 10px;
        margin-left: 20px;
        &-icon {
          margin-right: 10px;
        }
      }
      &__textarea {
        height: 75%;
        margin-bottom: 20px;
      }
      .label-and-button {
        display: flex;
        justify-content: space-between;
        .highlight-button {
          display: flex;
          opacity: 0.5;
          color: #36369B;
          align-items: center;
          margin-bottom: 6px;
          cursor: pointer;
          &__icon {
            margin-right: 5px;
          }
          &__text {
            font-size: 14px;
          }
          &--active {
            opacity: 1;
            font-family: "Nunito Sans Bold";
            color: #FFA800;
          }
        }
      }
      .shape-container {
        position: absolute;
        right: -23px;
        top: 41px;
      }
      .patient-selector-button {
        width: 100%;
        height: 55px;
        padding: 14px 20px 14px 20px;
        border-radius: 70px;
        border: none;
        background-color: #36369B;
        color: #FFFFFF;
        outline: none;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;
        text-align: left;
        &__img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0px 2px 6px #0000001A;
          font-size: 8px;
          vertical-align: middle;
        }
        &__info {
          display: flex;
          align-items: center;
          font-family: "Nunito Sans";
          font-size: 20px;
        }
        &__name {
          margin-left: 5px;
          line-height: 1;
          text-align: left;
        }
        &__icon-img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          box-shadow: 0px 2px 6px #0000001A;
          background-color: #FFFFFF;
          display: flex;
        }
        &__icon-patient {
          color: #8A8AC3;
          font-size: 20px;
          margin: auto;
        }
      }
    }
    .footer {
      display: flex;
      padding: 20px 40px;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #0000002B;
      &__caption {
        font-family: Nunito Sans;
        font-size: 15px;
        color: #707582;
      }
      &__button {
        width: auto;
      }
    }
  } 
  

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;

export const PatientSelection = styled.div`
  height: 100%;
  position: relative;
  box-sizing: border-box;
  .filter-patient {
    height: 68px;
    background-color: #F6F5F9;
    padding: 10px 20px;
    align-items: center;
    box-sizing: border-box;
    width: 100%; 
    border-radius: 78px;
    display: flex;
    cursor: text;
    &__input {
      background-color: transparent;
      font-family: "Nunito Sans";
      border: none;
      outline: none;
      font-size: 20px;
      color: #3E3DA3;
      width: 100%;
      ::placeholder {
        color: #3E3DA3;
        font-family: "Nunito Sans";
      }
    }
    &__icon {
      color: #3E3DA3 !important;
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .users-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    overflow-y: scroll;
    height: calc(100% - 88px);
    align-items: flex-start;
    align-content: flex-start;
    ::-webkit-scrollbar {
      width: 25px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      /* margin: 1px 0px;  */
    }
    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 10px 10px #3E3DA366;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: transparent;
    }
    &__responses {
      height: 100%;
      text-align: center;
      margin: auto;
      padding-left: 28px;
      display: flex;
    }
    .user-item {
      width: 42%;
      display: flex;
      padding: 8.5px 20px;
      border-radius: 12px;
      margin-bottom: 20px;
      background: #FCFCFE;
      align-items: center;
      color: #1F1F52;
      cursor: pointer;
      :nth-child(odd) {
        margin-right: 10px;
      }
      &--selected {
        color: #FCFCFE;
        background-color: #6C6BCC;
      }
      &__info {
        margin-left: 10px;
      }
      &__name {
        font-size: 17px;
        font-family: "Nunito Sans";
      }
      &__program {
        color: #68688D;
        font-family: "Nunito Sans";
        font-size: 12px;
        &--selected {
          color: #FCFCFE;
        }
      }
    }
  }
  
`