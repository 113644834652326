import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";
const bgFlorence = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/background-florence.svg`;

export const ModalUserAiNavigator = styled.div`
 display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: #F0EFF9;
    max-width: 1038px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    justify-content: end;
    display: flex;
    flex-direction: column;
    &__reaction {
      width: 1038px;
      margin: 200px 0;
    }
    .header{
      padding: 10px 10px 0px 10px;
      display: flex;
      justify-content: flex-end;
      .close {
        width: 24px;
        height: 24px;
        background-color: #FCFCFE;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 6.196px;;
        cursor: pointer;
        color: #36369B;
      }
    }

    .content {    
      display: flex;
      flex-direction: column;
      padding: 16px 37px 39px 37px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      height: calc(100% - 34px);
      .patient-card {
        margin-bottom: 20px;
        padding: 20px 26px;
        border-radius: 14px;
        background: #FFF;
        display: flex;
        width: 963.65px;
        box-sizing: border-box;
        justify-content: space-between;
        &__info {
          align-items: center;
          display: flex;
          .patient-image {
            padding: 6.75px;
            backdrop-filter: blur(8.288250923156738px);
            overflow: hidden;
            box-shadow: 0px 0px 12px 0px rgba(35, 34, 157, 0.20);
            border-radius: 50%;
            height: fit-content;
            margin-right: 20px;
          }
          .patient-info {
            &__name {
              color: #1F1F52;
              font-family: "Nunito Sans Bold";
              font-size: 35.368px;
              margin-bottom: 5px;
            }
            &__program {
              font-family: "Nunito Sans";
              padding: 0px 5px;
              color: #526479;
              font-size: 17px;
              background-color: #EEF1F4;
              width: fit-content;
              border-radius: 4px;
              display: flex;
              text-overflow: ellipsis;
              overflow: hidden;
              height: 1.2em;
              white-space: nowrap;
              margin-bottom: 10px;
              /* strong {
              margin-right: 10px;
              } */
            }
            &__email {
              color: #1F1F52;
              font-family: "Nunito Sans";
              font-size: 17px;
              margin-bottom: 10px;
            }
            &__shortcuts {
              display: flex;
              .shortcut {
                display: flex;
                cursor: pointer;
                padding: 10px;
                border-radius: 10px;
                background: #EFEEF8;
                margin-right: 12px;
                color: #36369B;
                text-align: center;
                text-shadow: 0px 0px 11.4px rgba(255, 255, 255, 0.73);
                font-family: "Nunito Sans";
                font-size: 17px;
                &__icon {
                  font-size: 22px;
                  margin-left: 10px;
                  color: #6C6BC6;
                } 
              }
            }
          }
        }
        &__right {
          .numbers {
            display: flex;
            padding: 10px 40px 10px 20px;
            flex-direction: column;
            border-radius: 10px;
            background: #F0EFF9;
            box-sizing: border-box;
            &__title {
                display: flex;
                &-text {
                color: #1F1F52;
                font-family: "Nunito Sans Bold";
                font-size: 14px;
                margin-right: 10px
                }
                &-icon {
                font-size: 15px;
                }
            }
            .number {
              margin-top: 10px;
              &__title {
                font-family: Nunito Sans;
                font-size: 12px;
                font-weight: 300;
                color: #4A4A75; 
              }
              &__phone {
                font-family: Nunito Sans;
                font-size: 14px;
                font-weight: 300;
                color: #1F1F52;
              }
            }
          }
          .button-email {
            display: flex;
            width: 244px;
            margin-top: 10px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: #6C6BCC;
            color: #FFF;
            text-align: center;
            box-shadow: 0px 0px 10px -3.111px rgba(35, 34, 157, 0.25);
            border: none;
            outline: none;
            cursor: pointer;
            &__text {
                font-family: "Nunito Sans Light";
                font-size: 14px;
            }
          }
        }
      }  
      .user-ainavigator-boxes {
        height: calc(100% - 177px);
        position: relative;
        overflow: auto;
      }
    }
  }
`;