import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalAddOutcomesEvent.style";
import * as P from "../../../../../utils/pipes";

// Components
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../../../graphql/queries/User";
import { MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS } from "../../../../../graphql/mutations/UserToProcess";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../graphql/queries/UserToProcess";
import { QUERY_GET_CUSTOM_FORMS_BY_IDS } from "../../../../../graphql/queries/CustomForm";

// Hooks
import useDoctorData from "../../../../../hooks/useDoctorData";
import usePatientList from "../../../../../hooks/usePatientList";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import GenericTemplate from "../../../outcomes/components/outcomesComponents/shared/genericTemplate";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import { QUERY_GET_PROCESS_BY_ID } from "../../../../../graphql/queries/Process";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput";
import { MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD, MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD } from "../../../../../graphql/mutations/DoctorToUserProcessBoard";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import Popup from "reactjs-popup";
import ProcessName from "../../processName";
import useUploadDoc from "../../../../../hooks/useUploadDoc";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const feather = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/feather.svg`;
const vitalSignLine = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/vitalSignLine.svg`;
const UPLOAD_DOCUMENT_ENDPOINT = process.env.REACT_APP_PATIENT_GUIDE_MEDPALM_ENDPOINT;
const BUCKET_OUTCOMES_FORMS = process.env.REACT_APP_BUCKET_FORM;

function ModalAddOutcomesEvent({ 
  setCreateEventActive,
  patientID,
  processID,
  cmpID,
}) {

  
  const {patientList, patientListLoading, patientListError} = usePatientList()
  const {uploadDoc, data, loading, error} = useUploadDoc();

  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();
  const [editUserToProcess, {error: updatevraiblesconsolelog}] = useMutation(
    MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS
  );

  console.log(updatevraiblesconsolelog);
  

  const [cmpSelected, setCmpSelected] = useState(null);
  const [processSelected, setProcessSelected] = useState(processID);
  const [patientSelected, setPatientSelected] = useState(patientID);
  const [descriptionSelected, setDescriptionSelected] = useState("");
  const [dateSelected, setDateSelected] = useState(P.formatNumberDateInput(new Date(Date.now())));
  const [timeSelected, setTimeSelected] = useState(P.formatNumberTimeInput(new Date(Date.now())));
  const [formSuccess, setFormSuccess] = useState(null);
  const [patientListActived, setPatientListActived] = useState(!(patientID && processID));
  const [editionLoding, setEditionLoding] = useState(false);

  const {data: process} = useQuery(
    QUERY_GET_PROCESS_BY_ID,
    {
      variables: {
        processId: processSelected,
      },
    }
  );

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientSelected, processId: processSelected },
  });

  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError,
  } = useQuery(QUERY_GET_CUSTOM_FORMS_BY_IDS, {
    variables: {
      userId: patientSelected,
      customFormId: process?.process[0].processToCustomForms[0]?.customFormId,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });

  useEffect(() => {    
    if(customForm?.customForm_by_pk.formGroups?.find((cmp) => cmp.id === cmpID)) {
      setCmpSelected(
        customForm?.customForm_by_pk.formGroups?.find((cmp) => cmp.id === cmpID)
      )
    }
  }, [cmpID, customForm]);

  const [customFormChangesList, setCustomFormChangesList] = useState([]);

  useEffect(() => {
    setCustomFormChangesList([])
  }, [cmpSelected])

  const insertFormField = async (e) => {
    let tempList = customFormChangesList.filter(
      (item) =>
        item.customFormFieldId !== e.customFormFieldId &&
        (item.customFormFieldParentId === e.customFormFieldId
          ? item.parentKey === e.key
          : true)
    );
    setCustomFormChangesList([...tempList, e]);
  };

  const handleSubmit = async () => {
    
    setEditionLoding(true);
    
    if (customFormChangesList?.find(item => item.documentToSave)) {
      for (const item of customFormChangesList.filter(item => item.documentToSave)) {
        let { data: { uploaded_file_data } } = await uploadDoc(item.documentToSave, UPLOAD_DOCUMENT_ENDPOINT, BUCKET_OUTCOMES_FORMS);
        item.value = item.documentToSave.name;
        item.key = uploaded_file_data[0].file_url;
      }
    }
    
    if (patientSelected && processSelected && cmpSelected) {
      const objectCustomFormChanges = customFormChangesList
        ?.filter(item => (item.key && item.value))
        .map((item) => {
          return {
            customFormFieldId: item.customFormFieldId,
            value: item.value,
            key: item.key,
            doctorId: doctorData?.id,
            userId: patientSelected,
          };
        });
    
      const changesString = customFormChangesList
        ?.filter(item => (item.key && item.value))
        .map((item) => {
          if (item.value instanceof Date)
            return P.shortDate(item.value, t, true, language);
          else return item.value;
        });
    
      // Ejecutar editUserToProcessFunction después de que todo el proceso de upload haya terminado
      editUserToProcessFunction(
        changesString,
        objectCustomFormChanges
      );
    }
  };

  const editUserToProcessFunction = async (
    changesString,
    objectCustomFormChanges
  ) => {    
  
    console.log("djshfkjahskfjhasd", {
      processId: processSelected,
      userId: patientSelected,
      doctorId: doctorData.id,
      stageId: "enroll",
      descript: descriptionSelected,
      customFormFieldChanges: changesString.map((item, index) => {return (index === 0 ? item : "*-,&" + item)}).toString(),
      latestCustomFormId: cmpSelected.id,
      customFormId: cmpSelected.id,
      objectsCustomForms: objectCustomFormChanges,
    });
    

    try {
      await editUserToProcess({
        variables: {
          processId: processSelected,
          userId: patientSelected,
          doctorId: doctorData.id,
          stageId: "enroll",
          descript: descriptionSelected,
          customFormFieldChanges: changesString.map((item, index) => {return (index === 0 ? item : "*-,&" + item)}).toString(),
          latestCustomFormId: cmpSelected.id,
          customFormId: cmpSelected.id,
          objectsCustomForms: objectCustomFormChanges,
        },
      });
      setCmpSelected(null);
      setDescriptionSelected("");
      setDateSelected(P.formatNumberDateInput(new Date(Date.now())));
      setTimeSelected(P.formatNumberTimeInput(new Date(Date.now())));
      if(!patientID) {
        setPatientSelected(null);
      }
      if(!processID) {
        setProcessSelected(null);
      }
      setFormSuccess(true);
      setEditionLoding(false);
    } catch (err) {
      setCmpSelected(null);
      setDescriptionSelected("");
      setDateSelected(P.formatNumberDateInput(new Date(Date.now())));
      setTimeSelected(P.formatNumberTimeInput(new Date(Date.now())));
      if(!patientID) {
        setPatientSelected(null);
      }
      if(!processID) {
        setProcessSelected(null);
      }
      setFormSuccess(false);
      setEditionLoding(false);
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setCreateEventActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  console.log("ldksflkasjdlkjfsad", cmpSelected);
  

  if(
    userToProcessDataLoading || customFormLoading || editionLoding
  ) 
    return(
      <S.ModalAddOutcomesEvent>
        <div className="modal-background">
        </div>
        <div className="modal-container modal-container--reaction">
          <ZaiaReaction
            widthImg={"150px"}
            zaiaImg={logoZaiaLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.ModalAddOutcomesEvent>
    )

  if(formSuccess)
    return(
      <S.ModalAddOutcomesEvent>
        <div className="modal-background" onClick={() => setCreateEventActive(false)}>
        </div>
        <div className="modal-container modal-container--reaction">
          <ZaiaModalResponseContent
            button1={() => setCreateEventActive(null)}
            formSuccess={formSuccess}
            button1Text={t("globally.done")}
            title={t("outcomes.modalOutcomes.successForm")}
          />
        </div>
      </S.ModalAddOutcomesEvent>
    )

  if(formSuccess === false)
    return(
      <S.ModalAddOutcomesEvent>
        <div className="modal-background" onClick={() => setCreateEventActive(false)}>
        </div>
        <div className="modal-container modal-container--reaction">
          <ZaiaModalResponseContent
            button1={() => setCreateEventActive(null)}
            formSuccess={formSuccess}
            title={t("globally.errorForm")}
            subtitle={t("outcomes.modalOutcomes.errorForm")}
            button1Text={t("globally.accept")}            
          />
        </div>
      </S.ModalAddOutcomesEvent>
    )

  return (
    <S.ModalAddOutcomesEvent>
      <div className="modal-background" onClick={() => setCreateEventActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__title">{t("outcomes.modalOutcomes.title")}</div>
            <div className="header__subtitle">{t("outcomes.modalOutcomes.subtitle1")} {t("outcomes.modalOutcomes.subtitle2")} {t("outcomes.modalOutcomes.subtitle3")}</div>
          </div>
          <div className="header__close" onClick={() => setCreateEventActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="rows-container">
          <div className="rows">
            <div className="principal-input">
              <EventOutcomesInputs
                patientID={patientID}
                processID={processID}
                cmpID={cmpID}

                setCmpSelected={setCmpSelected}
                cmpSelected={cmpSelected}

                patientSelected={patientSelected}
                setPatientSelected={setPatientSelected}
                processSelected={processSelected}
                setProcessSelected={setProcessSelected}
                setDescriptionSelected={setDescriptionSelected}
                descriptionSelected={descriptionSelected}
                dateSelected={dateSelected}
                setDateSelected={setDateSelected}
                timeSelected={timeSelected}
                setTimeSelected={setTimeSelected}
                setPatientListActived={setPatientListActived}
              />
            </div>
              {
                !patientListActived &&
                <div className="custom-form-inputs">
                  {
                    !cmpSelected &&
                    <ZaiaReaction
                      zaiaImg={feather}
                      text={t("outcomes.modalOutcomes.modalCaption")}
                      sizeText="18px"
                      textColor="#707582"
                      widthImg="400px"
                      widthText={"400px"}
                      weightText={false}
                    />
                  }
                  <GenericTemplate
                    title={P.dataTranslation(
                      cmpSelected?.customFormName,
                      language
                    )}
                    dataForm={customForm?.customForm_by_pk.formGroups?.find(
                      (cmp) => cmp.id === cmpSelected?.id
                    )}
                    insertfunction={insertFormField}
                    customFormChangesList={customFormChangesList}
                  />
                </div>  
              }
              {
                patientListActived &&
                <div className="custom-form-inputs">
                  <PatientSelection 
                    patientList={patientList}
                    patientListLoading={patientListLoading}
                    patientListError={patientListError}
                    patientSelected={patientSelected}
                    setPatientSelected={setPatientSelected}
                    setPatientListActived={setPatientListActived}
                  />
                </div>  
              }
          </div>
        </div>
        <div className="footer">
          <div className="footer__caption">
            {t("outcomes.modalOutcomes.selectTypeOfEvent")}
          </div>
          <div 
            className="footer__button"
            onClick={() =>  handleSubmit()}
          >
            <ZaiaButton
              type="button"
              option="primary"
              backgroundColor={"#17AF54"}
              title={t("globally.createEvent")}
              // title={t("outcomes.tableOutcomes.modalNewEvent.add")}
              borderRadius="75"
              padding={"8px 33px"}
              fontSize="14"
              height="35"
              fontFamily={"Nunito Sans"}
              shadow={false}
              disabled={
                !patientSelected || !processSelected || !cmpSelected ? true : false
              }
            />
          </div>
        </div>
      </div>
    </S.ModalAddOutcomesEvent>
  );
}

function PatientSelection({
  patientList,
  patientListLoading,
  patientListError,
  patientSelected,
  setPatientSelected,
  setPatientListActived
}) {  

  const {t, i18n: { language }} = useTranslation("global"); 
  const [inputText, setInputText] = useState('');

  const getFilterText = (text) => {
    return inputText 
      ? String(P.removeAccent(text)).toLowerCase().includes(P.removeAccent(inputText).toLowerCase())
      : true
  }

  return (
    <S.PatientSelection> 
      <div className={`filter-patient`}>
          <i className={`filter-patient__icon icon zaia-icono-search`}></i>
          <input 
            className={`filter-patient__input`} 
            type="text" 
            placeholder={t("globally.searchPatient")} 
            onChange={(event) => setInputText(event.target.value)}
          />
        </div>
        <div className="users-list">
          {
            patientListError && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                text={t("globally.resultAlarm")}
                sizeText="20px"
                textColor="var(--blue-color)"
                widthImg="110px"
              />
            </div>
          }
          {
            patientListLoading && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaLoading}
                sizeText="20px"
                textColor="var(--blue-color)"
                widthImg="110px"
              />
            </div>
          }
          {
            !patientListError && !patientListLoading && (patientList?.length === 0 || patientList?.filter((patient) => getFilterText(`${patient.name} ${patient.lastname}`)).length === 0) && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaEmpty}
                text={t("globally.noResults")}
                subText={t("globally.noMatchingPatients")}
                sizeText="33.25px"
                sizeSubText="15px"
                textColor="var(--blue-color)"
                widthImg="210px"
              />
            </div>
          }
          {
            !patientListError && !patientListLoading && patientList?.length !== 0 &&
            patientList?.map((patient) => 
              {
                if(getFilterText(`${patient.name} ${patient.lastname}`)) {
                  return(
                  <div 
                    className={`user-item ${patientSelected?.id === patient?.id && 'user-item--selected'}`}  
                    onClick={() => 
                      {
                        if(patientSelected?.id !== patient?.id) {
                          setPatientSelected(patient.id);
                          setPatientListActived(false)
                        }
                      }
                    }
                  >
                    <ZaiaImage
                      size={55}
                      img={patient.picture}
                      rounded={true}
                    />
                    <div className="user-item__info">
                      <div className="user-item__name">{`${patient.name} ${patient.lastname}`}</div>
                      <div className={`user-item__program ${patientSelected?.id === patient?.id && 'user-item__program--selected'}`}>
                        {
                          <Popup
                            trigger={
                              <div className="patient-option__info-program-detail">
                                {
                                  patient?.userToProcesses.length > 1 
                                  ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                                  <div>{P.dataTranslation(patient?.userToProcesses[0].process?.processName, language)}</div>
                                  
                                }
                              </div>
                            }
                            keepTooltipInside
                            nested
                            // position={"right bottom"}
                            on="hover"
                            disabled={patient?.userToProcesses.length > 1 ? false : true}
                          >
                            {
                              <ProcessName userToProcesses={patient?.userToProcesses}/>
                            }
                          </Popup>
                        }
                      </div>
                    </div>
                  </div>
                  )
                } else {
                  return null
                }
              }
            )
          }
        </div>
    </S.PatientSelection>
  )

}

const EventOutcomesInputs = ({
  patientID,
  processID,
  cmpID,
  setCmpSelected,
  cmpSelected,
  patientSelected,
  setPatientSelected,
  processSelected,
  setProcessSelected,
  setDescriptionSelected,
  descriptionSelected,
  dateSelected,
  setDateSelected,
  timeSelected,
  setTimeSelected,
  setPatientListActived,
  // customFormsList
}) => {
  const { data: userData, error } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: patientSelected },
  });
  const { doctorData } = useDoctorData();
  const [insertDoctorToUserProcessBoard] = useMutation(
    MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [deleteDoctorToUserProcessBoard] = useMutation(
    MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD
  );

  const [customFormsList, setCustomFormsList] = useState();
  const { t, i18n: { language } } = useTranslation("global");
  const [ cmpHighlighted, setCmpHighlighted ] = useState(null);
  const [editionHighlightLoding, setEditionHighlightLoding] = useState(false);

  const {data: process, loading: processLoading, error: processError} = useQuery(
    QUERY_GET_PROCESS_BY_ID,
    {
      variables: {
        processId: processSelected,
      },
    }
  );

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientSelected, processId: processSelected }, 
  });

  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError
  } = useQuery(QUERY_GET_CUSTOM_FORMS_BY_IDS, {
    variables: {
      userId: patientSelected,
      customFormId: process?.process[0]?.processToCustomForms[0]?.customFormId,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });

  useEffect(() => {
    if(customForm) {
      setCustomFormsList([...customForm?.customForm_by_pk.formGroups]);
    } else {
      setCustomFormsList([]);
    }
  }, [customForm])

  useEffect(() => {
    console.log("cmpSelected", cmpSelected);
    
    if(cmpSelected) {
      setCmpHighlighted(cmpSelected?.DoctorToUserProcessBoards[0])
    }
  }, [cmpSelected])

  const {patientList} = usePatientList();

  const deleteBoardData = async (doctorToUserProcessBoardId) => {
    setEditionHighlightLoding(true);
    try {
      await deleteDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
        },
      });
      setEditionHighlightLoding(false);
      setCmpHighlighted(null);
      // const cmpSelectedTemp = JSON.parse(JSON.stringify(cmpSelected));
      // setCmpSelected(null);
      // cmpSelectedTemp.DoctorToUserProcessBoards = [];
      // setCmpSelected(cmpSelectedTemp);
    } catch (err) {
      setEditionHighlightLoding(false);
      console.log(err);
    }
  };


  const insertBoardData = async (customFormId) => {
    setEditionHighlightLoding(true);
    try {
      const response = await insertDoctorToUserProcessBoard({
        variables: {
          customFormId: customFormId,
          typeView: "new",
          doctorId: doctorData.id,
          userToProcessId: userToProcessData.userToProcess[0].id,
        },
      });
      setCmpHighlighted(response.data.insert_doctorToUserProcessBoard_one);
      setEditionHighlightLoding(false);
      // const cmpSelectedTemp = JSON.parse(JSON.stringify(cmpSelected));
      // cmpSelectedTemp.DoctorToUserProcessBoards = [];
      // cmpSelectedTemp.DoctorToUserProcessBoards.push(
      //   response.data.insert_doctorToUserProcessBoard_one
      // );
      // setCmpSelected(null);
      // console.log(response.data.insert_doctorToUserProcessBoard_one);
      // cmpSelectedTemp.DoctorToUserProcessBoards = response.data.insert_DoctorToUserProcessBoard;
      // setCmpSelected(cmpSelectedTemp);
    } catch (err) {
      setEditionHighlightLoding(false);
      console.log(err);
    }
  };

  const handleKeyUp = (e, msg) => {
    if(e.target.scrollHeight !== 22) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`; 
    }
    if(msg === ""){
      e.target.style.height = '22px';
    }
    // In case you have a limitation
    // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
  }

  console.log("asdf", cmpSelected);  

  const patientSelectedInfo = useMemo(() => {    
    return patientList?.find( patient => patient.id === patientSelected);
  }, [patientSelected, patientList]);

  if(
    editionHighlightLoding
  ) 
    return(
      <ZaiaReaction
        widthImg={"150px"}
        zaiaImg={logoZaiaLoading}
        padding={"200px 0px"}
      />
    )

  return (
    <div className="principal-input__container">
      
      {
        patientID && processID
        ? <div className="patient-box">
          <img src={patientSelectedInfo?.picture} alt="" className="patient-box__img" />
          <div className="patient-box__info">
            {
              patientSelectedInfo &&
              <div className="patient-box__name">{
                patientSelectedInfo?.name + " " + patientSelectedInfo?.lastname 
              }</div>
            }
            <div className="patient-box__program">{t("globally.program")} <b>{P.dataTranslation(userData?.user[0]?.userToProcesses?.find(user => user.process?.id === processSelected).process.processName, language)}</b></div>
          </div>
        </div>
        : <>
          <div className="input-box">
            <div className="input-box__label">
              {t("globally.selectPatient")}
            </div>
            <button className="patient-selector-button" onClick={() => setPatientListActived(true)}>
              <div className="patient-selector-button__info">
                {
                  !patientSelected
                  ? <div className="patient-selector-button__icon-img">
                    <i className="patient-selector-button__icon-patient zaia-i-person"/>
                  </div> 
                  : <img src={patientSelectedInfo?.picture} alt="" className="patient-selector-button__img" />
                }
                <div className="patient-selector-button__name">
                  {
                    patientSelected
                    ? patientSelectedInfo?.name + " " + patientSelectedInfo?.lastname
                    : t("globally.selectPatient")
                  }
                </div>
              </div>
              <i className="patient-selector-button__icon zaia-i-next"/>
            </button>
          </div>
          <div className="input-box">
            <div className="input-box__label">
              {t("globally.selectProgram")}
            </div>
            <ZaiaSelect
              type="select"
              name="userToProcessId"
              placeholder={t("globally.selectProgram")}
              padding={"14px 20px"}
              borderRadius={"70px"}
              textColor={"#FFFFFF"}
              activeBorder={false}
              backgroundColor={"#36369B"}
              textItemsColor={"#36369B"}
              boxShadow={"0px 0px 35.7px 0px #D1C8FF"}
              fontSize={"20px"}
              placeholderTextColor={"#FFFFFF"}
              setValue={(e) => setProcessSelected(e)}
              value={processSelected}
              items={userData?.user[0]?.userToProcesses?.map(
                user => {
                  return {
                    value: user?.process?.id, 
                    text: `${P.dataTranslation(user?.process?.processName, language)}`
                  }
                }
              ).sort(function(a,b){
                return a.text.localeCompare(b.text);
              })}
              shadow={false}
              errorOption={false}
              disabled={(!patientSelected || processID)? true : false}
              heightList={"300px"}
            />
          </div>
        </>
      }

      <div className="input-box">
        <div className="label-and-button">
          <div className="input-box__label">
            {/* <i className="input-box__label-icon icon zaia-i-add-event"/> */}
            {t("globally.typeOfEvent")}
          </div>
          {
            cmpSelected &&
            <div 
              className={`highlight-button ${ cmpHighlighted && "highlight-button--active"}`} 
              onClick={
                () => 
                cmpHighlighted 
                ? deleteBoardData(cmpHighlighted.id)
                : insertBoardData(cmpSelected?.id)
              }>
              <i className={`highlight-button__icon icon ${ cmpHighlighted ? "zaia-star-on" : "zaia-star-off"}`}></i>
              <div className="highlight-button__text">{t("aiNavigator.outcomes.addToHighlights")}</div>
            </div>
          }
        </div>
        <ZaiaSelect
          type="select"
          name="cmpId"
          placeholder={t("outcomes.modalOutcomes.event")}
          padding={"14px 20px"}
          borderRadius={"70px"}
          textColor={"#FFFFFF"}
          activeBorder={false}
          backgroundColor={"#36369B"}
          textItemsColor={"#36369B"}
          boxShadow={"0px 0px 35.7px 0px #D1C8FF"}
          placeholderTextColor={"#FFFFFF"}
          heightList={"200px"}
          fontSize="20px"
          items={customFormsList?.map((customForm) => {
            return {
              value: customForm.id,
              text: P.dataTranslation(
                customForm.customFormName,
                language
              ),
            };
          })}
          setValue={(e) => setCmpSelected(
            customForm?.customForm_by_pk.formGroups?.find((cmp) => cmp.id === e)
          )}
          value={cmpSelected?.id}
          shadow={false}
          disabled={(!patientSelected || !processSelected || cmpID || customFormsList?.length === 0)? true : false}
          errorOption={false}
        />
      </div>
      {
        cmpSelected &&
        <div className="input-box input-box--last">
          <div className="input-box__label">
            <i className="input-box__label-icon icon zaia-i-message-outline"/>
            {t("outcomes.modalOutcomes.addNotes")}
          </div>
          <div className="input-box__textarea">
            <ZaiaInput
              textColor={"#36369B"}
              activeBorder={false}
              placeholderTextColor={"#878792"}
              backgroundColor={"#EDEDFC"}
              fontSize={"15px"}
              placeholder={t("globally.eventDescription")}
              type="text"
              name="description"
              padding={"20px 27px"}
              borderRadius={"14px"}
              textarea={true}
              errorOption={false}
              height={"100%"}
              getText={(e) => setDescriptionSelected(e)}
              value={descriptionSelected}
              onKeyUp={(e) => handleKeyUp(e, descriptionSelected)}
            />
          </div>
        </div>
      }
      {
        !cmpSelected &&
        <img src={vitalSignLine} alt="" className="principal-input__decorator"/>
      }
    </div>
  )
}


export default ModalAddOutcomesEvent;
