import React, { useEffect, useMemo, useState } from "react";
import * as S from "./PatientsList.style";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../../../zaiaComponents/zaiaImage";
import Popup from "reactjs-popup";
import ProcessName from "../../../../../shared/processName";

// pipes
import * as P from "../../../../../../../utils/pipes";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import useRecordNavigation from "../../../../../../../hooks/useRecordNavigation";
import { Actions } from "../../../../../../../utils/actionsIds";
import usePatientList from "../../../../../../../hooks/usePatientList";
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED } from "../../../../../../../graphql/queries/UserToProcess";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../../../graphql/queries/Process";
import Modal from "../../../../../../../utils/modal";
import ModalCreateInvitePatient from "../../../../../shared/modals/modalCreateInvitePatient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// Routes
import { Routes } from "../../../../../../../utils/constants";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function PatientsList({
  setSelectedPatient,
  inputText,
  filterProcess,
  filterGender
}) {

  const {t, i18n: { language }} = useTranslation("global");
  const { recordAction } = useRecordNavigation();
  const history = useHistory();
  const { PROFILE } = Routes;

  const { data: processTypes } = useQuery(QUERY_GET_PROCESS_TYPES);
  const {patientList, patientListError, patientListLoading} = usePatientList();
  const {data: userToProcessesList} = useQuery(QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED, {variables: {isAcepted: true}});


  const [usersListInitial, setUsersListInitial] = useState([]);
  const [usersFilterByProgram, setUsersFilterByProgram] = useState(userToProcessesList?.userToProcess ? userToProcessesList?.userToProcess : []);
  const [usersList, setUsersList] = useState(userToProcessesList?.userToProcess ? userToProcessesList?.userToProcess : []);

  const [filterAZ, setFilterAZ] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterProgram, setFilterProgram] = useState(null);
  const [filterProgramStatus, setFilterProgramStatus] = useState(null);

  const [createInvitePatientActive, setCreateInvitePatientActive] = useState(false);

  useEffect(() => {
    setUsersListInitial(userToProcessesList?.userToProcess);
    setUsersFilterByProgram(userToProcessesList?.userToProcess);
    setUsersList(userToProcessesList?.userToProcess);
  }, [userToProcessesList]);  

  const getFilterText = (text) => {
    return inputText 
      ? String(text).toLowerCase().includes(inputText.toLowerCase())
      : true
  }

  const filteredPatients = () => {

    return usersList?.filter((item) => (
      (getFilterText(`${item.user.name} ${item.user.lastname}`) || 
      getFilterText(item.user.email) ) &&
      (
        filterProcess.length > 0 
        ? filterProcess.find((process) => item.user.userToProcesses.find((item) => item.process.id === process.id))
        : true
      ) && 
      (
        filterGender
        ? item.user.gender === filterGender
        : true
      )
    ))
  } 

  const filteredPatientsByDate = () => {

    if (filterDate === null) {
      return usersFilterByProgram;
    } else if (filterDate === true) {
        return [...usersFilterByProgram].sort((a, b) => {

            return new Date(b.created_at) - new Date(a.created_at);  
        });
    } else {
        return [...usersFilterByProgram].sort((a, b) => {

            return new Date(a.created_at) - new Date(b.created_at);  
        });
    }
  }
  
  const filteredPatientsByAZ = () => {
    if (filterAZ === null) {
        return usersFilterByProgram;
    } else if (filterAZ === false) {
        return [...usersFilterByProgram].sort((a, b) => {
            const nameA = " " + a.user.lastname;
            const nameB = " " + b.user.lastname;

            return nameA.localeCompare(nameB);  
        });
    } else {
        return [...usersFilterByProgram].sort((a, b) => {
            const nameA = " " + a.user.lastname;
            const nameB = " " + b.user.lastname;

            return nameB.localeCompare(nameA); 
        });
    }
  };

  const filteredPatientsByProgram = () => {
    setFilterAZ(null);
    setFilterDate(null);
    if(filterProgram) {
      const filteredByProgram = usersListInitial?.filter((item) => {
        return filterProgram === item.process.id
      })
      setUsersFilterByProgram(filteredByProgram);
      setUsersList(filteredByProgram);
    } else {
      setUsersFilterByProgram(usersListInitial);
      setUsersList(usersListInitial);
    }
  } 

  const filteredPatientsByProgramStatus = () => {
    setFilterAZ(null);
    setFilterDate(null);
    if(filterProgramStatus) {
      const filteredByProgramStatus = usersListInitial?.filter((item) => {
        return filterProgramStatus === item.processStage.id && filterProgram === item.process.id
      })
      setUsersFilterByProgram(filteredByProgramStatus);
      setUsersList(filteredByProgramStatus);
    } else if(filterProgram) {
      filteredPatientsByProgram();
    } else {
      setUsersFilterByProgram(usersListInitial);
      setUsersList(usersListInitial);
    }
  }

  useEffect(() => {
    setUsersList(filteredPatientsByAZ());
  }, [filterAZ]);

  useEffect(() => { 
    setUsersList(filteredPatientsByDate());
  }, [filterDate]);
  
  useEffect(() => { 
    filteredPatientsByProgram();
  }, [filterProgram]);

  useEffect(() => { 
    filteredPatientsByProgramStatus();
  }, [filterProgramStatus]);


  if (patientListError)
    return (
      <S.PatientsList>
        <div className="table-container">
          <div className="zaia-reaction">
            <ZaiaReaction
              zaiaImg={logoZaiaAlert}
              widthImg="100px"
              text={t("globally.errorLoadingPatients")}
              sizeText="15px"
              widthText="240px"
            />
          </div>
        </div>
      </ S.PatientsList>
    )

  if(patientListLoading) 
    return(
      <S.PatientsList>
        <div className="table-container">
          <div className="zaia-reaction">
            <ZaiaReaction
              widthImg="150px"
              zaiaImg={logoZaiaLoading}
            />  
          </div>
        </div>
      </S.PatientsList>
    )

  
  

  return (
    <S.PatientsList>
      <Modal open={createInvitePatientActive}>
        <ModalCreateInvitePatient
          setCreateInvitePatientActive={setCreateInvitePatientActive}
        />
      </Modal>
      <div className="table-container">
        <table className="suggestion-table">
          {
            false
            ? <thead className="suggestion-table__head">
              <tr>
                <th className="title-patient-name"> 
                  {t("globally.patientName")}
                </th>
                <th className="title-email">
                  {t("globally.email")}
                </th>
                <th className="title-program">
                  {t("globally.program")}
                </th>
              </tr>
            </thead>
            : <thead className="suggestion-table__head">
              <tr>
                <th className="filter filter--patient-name"> 
                  <div className="filter__label">{t("aiNavigator.filters.orderPatients")}:</div>
                  <div className="filter__space">
                    <button 
                      className={`patient-order ${filterAZ !== null && "patient-order--actived"}`}
                      onClick={() => setFilterAZ(
                        filterAZ === null ? true : (filterAZ === false ? null : false)
                      )}
                    >
                      <i className={`patient-order__icon icon zaia-i-filters ~iconchange__text ${filterAZ !== null && "patient-order__icon--actived"}`}></i>
                      <div className="patient-order__text">
                        {t("aiNavigator.filters.orderAZ")} <b>
                          {
                            filterAZ === false 
                            ? "Z-A"
                            : "A-Z"
                          }
                        </b>
                      </div>
                    </button>
                  </div>
                </th>
                <th className="filter filter--date">
                  <div className="filter__label">{t("aiNavigator.filters.orderEnrrollDate")}:</div>
                  <div className="filter__space">
                    <button 
                      className={`patient-order ${filterDate !== null && "patient-order--actived"}`}
                      onClick={() => setFilterDate(
                        filterDate === null ? true : (filterDate === false ? null : false)
                      )}
                    >
                      <div className="patient-order__text">
                        {
                          filterDate === false
                          ? t("aiNavigator.filters.oldest")
                          : filterDate === true
                            ? t("aiNavigator.filters.mostRecent")
                            : t("aiNavigator.filters.tapToOrder")
                        }
                      </div>
                    </button>
                  </div>
                </th>
                <th className="filter filter--program">
                  <div className="filter__label">{t("aiNavigator.filters.filterByProgram")}</div>
                  <div className="filter__space">
                    <Popup
                      trigger={
                        <button className={`program-filter ${filterDate !== null && "program-filter--actived"}`}>
                          <div className="program-filter__text">
                            {filterProgram ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processName, language) : t("globally.all")}
                          </div>
                          <i className="program-filter__icon icon zaia-icono-dropdown-light"></i>
                        </button>
                      }
                      closeOnDocumentClick
                      keepTooltipInside
                      position={["bottom center"]}
                      nested
                    >
                      {
                        close => (
                          <S.ProgramSelector>
                            <div className="header">
                              <div className="header__label">{t("globally.program")}</div>
                              <div className="header__label">{t("globally.patient")}</div>
                            </div>
                            <div className="list-programs">
                              <div className={`program-item ${filterProgram === null && "program-item--actived"}`}
                                onClick={() => {
                                  setFilterProgram(null);
                                  setFilterProgramStatus(null);
                                  close();
                                }}
                              >
                                <div className="program-item__name">{t("globally.all")}</div>
                                <div className="program-item__quantity">
                                  <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                  <div className="program-item__number">{usersList?.length}</div>
                                </div>
                              </div>
                              {
                                processTypes?.process.map((item) => {
                                  return (                                
                                    <div className={`program-item ${filterProgram === item.id && "program-item--actived"}`}
                                      onClick={() => {
                                        setFilterProgram(item.id);
                                        setFilterProgramStatus(null);
                                        close();
                                      }}
                                    >
                                      <div className="program-item__name">{P.dataTranslation(item.processName, language)}</div>
                                      <div className="program-item__quantity">
                                        <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                        <div className="program-item__number">{item.userToProcesses?.length}</div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </S.ProgramSelector>
                        )
                      }
                    </Popup>                   
                  </div>
                </th>
                <th className="filter filter--program-status">
                  <div className="filter__label filter--program-status__label">{t("aiNavigator.filters.programStatus")}</div>
                  <div className="filter__space filter--program-status__space">
                    {
                      filterProgram === null 
                      ? <div
                        className="status-all"
                      >
                        <i className="status-all__icon icon zaia-i-lock-chat"></i>
                        <div className="status-all__text">{t("globally.all")}</div>
                      </div>
                      : <Popup
                        trigger={
                          <button className="program-filter">
                            <div className="program-filter__text">
                              {
                                filterProgram && filterProgramStatus 
                                ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processStages.find((item) => item.id === filterProgramStatus)?.stageName, language)
                                : t("globally.all")
                              }
                            </div>
                            <i className="program-filter__icon icon zaia-icono-dropdown-light"></i>
                          </button>
                        }
                        closeOnDocumentClick
                        keepTooltipInside
                        position={["bottom center"]}
                        nested
                      >
                        {
                          close => (
                            <S.ProgramSelector>
                              <div className="header">
                                <div className="header__label">{t("aiNavigator.filters.programStatus")}</div>
                                <div className="header__label">{t("globally.patient")}</div>
                              </div>
                              <div className="list-programs">
                                <div className={`program-item ${filterProgramStatus === null && "program-item--actived"}`}
                                  onClick={() => {
                                    setFilterProgramStatus(null);
                                    close();
                                  }}
                                >
                                  <div className="program-item__name">{t("globally.all")}</div>
                                  <div className="program-item__quantity">
                                    <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                    <div className="program-item__number">{processTypes?.process.find((item) => item.id === filterProgram).userToProcesses.length}</div>
                                  </div>
                                </div>
                                {
                                  processTypes?.process.find((item) => item.id === filterProgram)?.processStages.map((item) => {
                                    
                                    return (
                                      <div className={`program-item ${filterProgramStatus === item.id && "program-item--actived"}`}
                                        onClick={() => {
                                          setFilterProgramStatus(item.id);
                                          close();
                                        }}
                                      >
                                        <div className="program-item__name">{P.dataTranslation(item.stageName, language)}</div>
                                        <div className="program-item__quantity">
                                          <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                          <div className="program-item__number">{
                                            processTypes?.process.find(
                                              (item) => 
                                                item.id === filterProgram
                                            ).userToProcesses.filter(
                                              (itemProcess) => 
                                              itemProcess.stageId === item.id
                                            ).length
                                          }</div>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </S.ProgramSelector>
                          )
                        } 
                      </Popup>     
                    }
                  </div>
                </th>
              </tr>
            </thead>
          }
          <tbody className="suggestion-table__body">
            { 
              filteredPatients()?.map((item, index) => {
                return (
                  <tr 
                    onClick={
                      () => {
                        recordAction(Actions.AINAVIGATOR.OPEN_MODAL_SUGGEST_PATIENT);
                        setSelectedPatient(item.user);
                      }
                    }
                    className="suggestion-table__item">
                    <td>
                      <div className="patient-content">
                        <ZaiaImage
                          img={item.user?.picture}
                          alt=""
                          size={40}
                          rounded={true}
                        />
                        <div className="patient-content__info">
                          <div className="patient-content__info-name-psa">
                          {`${item.user.name} ${item.user.lastname}`}
                          </div>
                          <div className="patient-content__info-email">
                            {item.user.email}
                          </div>
                          {
                            item.user.countryPhoneId && item.user.phone &&
                            <div className="patient-content__info-phone">
                              {"+" + item.user.countryPhoneId + " " + item.user.phone}
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="patient-date">
                        {P.shortDate(item.created_at, t, true, language)}
                      </div>
                    </td>
                    <td>
                      <div className="patient-program">
                        {P.dataTranslation(item.process?.processName, language)}
                      </div>
                    </td>
                    <td>
                      <div className="patient-status">
                        <i className="patient-status__icon icon zaia-i-refresh"></i>
                        <div className="patient-status__text">
                          {P.dataTranslation(item.processStage?.stageName, language)}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>  
      </div>
      <div className="footer">
        <div className="footer__label">
          <div className="footer__label-patients-quantity">
            {t("aiNavigator.your")} <b>{
              usersList?.length
            }</b> {t("aiNavigator.patientsInOnePlace")}
          </div>
          <div className="footer__label-patients-management">
            {t("aiNavigator.ifYouCantFindThePatient")}, <a onClick={() => {history.push(PROFILE.route + "?management=true")}}>
              {t("aiNavigator.goToPatientManagement")}
            </a>
          </div>
        </div>
        <div className="footer__button">
          <button className="button-add-patient"
            onClick={
              () => {
                recordAction(Actions.AINAVIGATOR.OPEN_MODAL_ADD_PATIENT);
                setCreateInvitePatientActive(true);
              }
            }
          >
            <div className="button-add-patient__text">
              {t("globally.addPatient")}
            </div>
            <i className="button-add-patient__icon icon zaia-icono-perfil ~cambiaricono"></i>
          </button>
        </div>
      </div>
    </S.PatientsList>
  );
}

export default PatientsList;
